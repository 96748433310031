// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-components-buttons-mdx": () => import("../src/components/Buttons.mdx" /* webpackChunkName: "component---src-components-buttons-mdx" */),
  "component---src-components-cards-mdx": () => import("../src/components/Cards.mdx" /* webpackChunkName: "component---src-components-cards-mdx" */),
  "component---src-components-containers-mdx": () => import("../src/components/Containers.mdx" /* webpackChunkName: "component---src-components-containers-mdx" */),
  "component---src-components-getting-started-mdx": () => import("../src/components/Getting-started.mdx" /* webpackChunkName: "component---src-components-getting-started-mdx" */),
  "component---src-components-media-mdx": () => import("../src/components/Media.mdx" /* webpackChunkName: "component---src-components-media-mdx" */),
  "component---src-components-utilities-mdx": () => import("../src/components/Utilities.mdx" /* webpackChunkName: "component---src-components-utilities-mdx" */),
  "component---src-components-forms-mdx": () => import("../src/components/Forms.mdx" /* webpackChunkName: "component---src-components-forms-mdx" */),
  "component---src-components-grid-mdx": () => import("../src/components/Grid.mdx" /* webpackChunkName: "component---src-components-grid-mdx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

